// Modules
import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
// Helpers
import { detectBrowserLanguage } from '../helpers/language'
// Translations
import en from './translator/en.json'
import de from './translator/de.json'

// the translations
const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
}

i18n.use(initReactI18next).init({
  supportedLngs: ['de', 'en'],
  resources,
  lng: detectBrowserLanguage(),
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false
  },
  saveMissing: true
})
export default i18n

// Modules
import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import { useTracking } from 'react-tracking'
import PropTypes from 'prop-types'
import Ripples from 'react-ripples'
import { Card, CardBody, Button } from 'reactstrap'
// Actions
import { trackPageViews } from '../../ducks/Tracking.ducks'
// Helpers
import { formatDate } from '../../helpers/dates'
import { maskEmail } from '../../helpers/emails'

export const AWARD_PAGE_VIEWS = 'Award Page Views'

const AwardDetails = ({
  translate,
  recepientEmail,
  award,
  badge,
  issuer,
  validation = {},
  handleGoBack = null
}) => {
  const { isAwardValid, isEmailAvailable, isEmailValid } = validation
  const recepientName = award['extensions:recipientProfile']?.name

  const { getTrackingData } = useTracking({ page: AWARD_PAGE_VIEWS })
  const { page } = getTrackingData()

  useEffect(() => {
    trackPageViews({ data: { page } })
  }, [])

  return (
    <>
      <div className="modal-buttons">
        <ul>
          <li>
            <Button
              color="mint"
              className={isEmailAvailable ? (isEmailValid ? '' : 'novalid') : 'novalid-grey'}>
              {isEmailAvailable
                ? isEmailValid
                  ? translate('validated.recipient.valid')
                  : translate('validated.recipient.not-valid')
                : translate('validated.recipient.not-available')}
            </Button>
          </li>
          <li>
            <Button color="mint" className={isAwardValid ? '' : 'novalid'}>
              {isAwardValid
                ? translate('validated.award.valid')
                : translate('validated.award.not-valid')}
            </Button>
          </li>
        </ul>
      </div>
      {isAwardValid && (
        <div className="grid-body detailed-section">
          <div className="left-container">
            <Card>
              <CardBody>
                <label>{translate('validated.badge.fields.name')}</label>
                <p className="scroll two-line-scroll">{badge?.name}</p>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {isEmailAvailable && isEmailValid ? (
                  <>
                    <label>{translate('validated.recipient.name')}</label>
                    <p className="scroll two-line-scroll">{recepientName}</p>
                    <span className="scroll">{maskEmail(recepientEmail)}</span>
                  </>
                ) : (
                  <p className="scroll full166 uppercase">
                    {isEmailAvailable
                      ? translate('validated.recipient.not-valid')
                      : translate('validated.recipient.not-available')}
                  </p>
                )}
              </CardBody>
            </Card>

            <Card className="badge-image">
              <CardBody>
                <label>{translate('validated.badge.fields.image')}</label>
                <div className="image">
                  <img
                    src={badge?.image.id || badge?.image}
                    className="img-fluid"
                    alt={badge.name}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="center-container">
            <Card>
              <CardBody>
                <label>{translate('validated.badge.fields.issuer')}</label>
                <p className="scroll">{issuer?.name}</p>
              </CardBody>
            </Card>
            <Card className={award?.expires ? 'badge-desc' : 'badge-desc-full'}>
              <CardBody>
                <label>{translate('validated.badge.fields.description')}</label>
                <p className="scroll">{parse(badge?.description)}</p>
              </CardBody>
            </Card>

            {award?.expires && (
              <Card>
                <CardBody>
                  <label>{translate('validated.badge.fields.expiry-date')}</label>
                  <p className="scroll">{formatDate(award?.expires)}</p>
                </CardBody>
              </Card>
            )}
          </div>
          <div className="right-container">
            <Card className="criteria-desc">
              <CardBody>
                <label>{translate('validated.badge.fields.criteria')}</label>
                <p className="scroll">{parse(badge?.criteria.narrative)}</p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <label>{translate('validated.badge.fields.issued-date')}</label>
                <p className="scroll">{formatDate(award?.issuedOn)}</p>
              </CardBody>
            </Card>
            <Card className="badge-offer-link">
              <CardBody>
                <label>{translate('validated.badge.fields.link')}</label>
                <a
                  className="text scroll"
                  href={`${badge?.criteria.id}`}
                  target="_blank"
                  rel="noreferrer">
                  {badge?.criteria.id}
                </a>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {handleGoBack && (
        <div className="text-center modal-big-btn">
          <Ripples className="react-20 color-off-white">
            <Button
              color="light-success"
              className="modal-big-btn btn-cu-xl"
              onClick={handleGoBack}>
              {translate('validated.buttons.validate-again')}
            </Button>
          </Ripples>
        </div>
      )}
    </>
  )
}

AwardDetails.propTypes = {
  translate: PropTypes.func,
  recipientEmail: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  award: PropTypes.object,
  badge: PropTypes.object,
  issuer: PropTypes.object,
  validation: PropTypes.object,
  handleGoBack: PropTypes.func
}

export default AwardDetails

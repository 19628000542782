/**
 * Detects the user's browser language.
 *
 * This function retrieves the preferred language set in the user's browser
 * using the `navigator.language` property. If `navigator.language` is not
 * available, it falls back to `navigator.userLanguage` for compatibility with
 * older versions of Internet Explorer.
 *
 * The function splits the language string at the '-' character (if present)
 * to return only the language code (e.g., 'en', 'de').
 *
 * @returns {string} The detected language code.
 */
export const detectBrowserLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage
  return userLang.split('-')[0]
}

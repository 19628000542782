// Modules
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Assets
import { MdOutlineLanguage } from 'react-icons/md'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const dropdownRef = useRef(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prevState) => !prevState)
  }, [])

  const handleLanguageSelect = useCallback(
    (language) => {
      i18n.changeLanguage(language)
      setIsDropdownOpen(false)
    },
    [i18n]
  )

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div className="language-dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown} aria-expanded={isDropdownOpen}>
        <MdOutlineLanguage size={22} color="#23366C" />
      </button>
      <ul className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
        <li>
          <button
            onClick={() => handleLanguageSelect('de')}
            className={i18n.language === 'de' ? 'active' : ''}>
            DE
          </button>
        </li>
        <li>
          <button
            onClick={() => handleLanguageSelect('en')}
            className={i18n.language === 'en' ? 'active' : ''}>
            EN
          </button>
        </li>
      </ul>
    </div>
  )
}

export default LanguageSwitcher
